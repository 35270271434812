import React from "react";
import { WhmBadge } from "@securitize/reactjs-whm";

interface TabIconProps {
  icon: React.ReactElement;
  hasBadge?: boolean;
  badgeTestId?: string;
}

const TabIcon: React.FC<TabIconProps> = ({ icon, hasBadge, badgeTestId }) => {
  return hasBadge ? (
    <WhmBadge color="error" data-test-id={badgeTestId} variant="dot">
      {icon}
    </WhmBadge>
  ) : (
    icon
  );
};

export default TabIcon;
