import React, { useContext } from "react";
import { WhmSkeleton, WhmStack, WhmTypography } from "@securitize/reactjs-whm";
import { LoadingCard } from "./LoadingSkeletonCard";
import { Translate } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { useResolvedPath } from "react-router";
import { I18nContext } from "../../../contexts/I18nContext";
import { useGetInvestorInformationQuery } from "../../../data/useProfile";
import { VerificationNeeded } from "./VerificationNeeded";
import { PersonalDetailsCard } from "./PersonalDetailsCard";
import { AddressCard } from "./AddressCard";
import { TaxJurisdictionCard } from "./TaxJurisdictionCard";
import { VerificationStatuses } from "../../../data/types/investors";

export interface PersonalInformationProps {
  isLoading: boolean;
}

export const PersonalInformation = ({
  isLoading,
}: PersonalInformationProps) => {
  const { pathname: currentPath } = useResolvedPath("");

  const { language } = useContext(I18nContext);
  const personalInfoQuery = useGetInvestorInformationQuery();

  if (personalInfoQuery.isPending || isLoading)
    return (
      <>
        <WhmSkeleton
          data-test-id="profile-personal-information-top-skeleton"
          sx={{ marginTop: "16px" }}
          variant="rounded"
          width={"100%"}
          height={52}
        ></WhmSkeleton>
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </>
    );

  if (
    personalInfoQuery.data?.verification.status === VerificationStatuses.NONE
  ) {
    return <VerificationNeeded currentPath={currentPath} />;
  }

  return (
    <>
      <WhmStack sx={{ padding: "16px 0px" }}>
        <WhmTypography variant="h4">
          <Translate
            translationKey={TranslationKeys.PROFILE_PERSONAL_INFORMATION_TITLE}
          />
        </WhmTypography>
        <WhmTypography variant="body2" color="GrayText">
          <Translate
            translationKey={
              TranslationKeys.PROFILE_PERSONAL_INFORMATION_SUBTITLE
            }
          />
        </WhmTypography>

        <PersonalDetailsCard
          personalInfoQuery={personalInfoQuery}
        ></PersonalDetailsCard>

        <AddressCard
          personalInfoQuery={personalInfoQuery}
          language={language}
        ></AddressCard>

        <TaxJurisdictionCard
          personalInfoQuery={personalInfoQuery}
          language={language}
        ></TaxJurisdictionCard>
      </WhmStack>
    </>
  );
  // TODO - No data scenario will show error screen in a future task
  // if (personalInfoQuery.isError) {
};
