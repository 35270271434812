import { styled, WhmButton, whmTheme } from "@securitize/reactjs-whm";
import { PaletteColor } from "@mui/material";

// This button overrides a contained button's background color which was being
// affected by a reset inside the container of this MFE.
// This component might not be needed once that is corrected on the container.
export const WhmCustomButton = styled(WhmButton)(
  ({
    color,
    variant,
    disabled,
  }: {
    color: keyof typeof whmTheme.palette;
    variant: "text" | "outlined" | "contained" | undefined;
    disabled?: boolean;
  }) =>
    variant === "contained" &&
    !disabled && {
      backgroundColor: `${
        (whmTheme.palette[color] as PaletteColor).main
      } !important`,
    },
);
