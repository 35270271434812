import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { WhmAutocomplete, WhmBox, WhmTextField } from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import {
  InfoCard,
  placeholderTextFieldStyle,
  textFieldStyle,
} from "../shared/InfoCard";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { InvestorDto } from "../../../data/dtos/investor.dto";
import { CountryTextField } from "./CountryTextField";
import { statesUS } from "../../Registration/countriesList";

interface AddressCardProps {
  personalInfoQuery: UseQueryResult<InvestorDto, Error>;
  language: string;
}

export const AddressCard: React.FC<
  React.PropsWithChildren<AddressCardProps>
> = ({ personalInfoQuery, language }) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_ADDRESS_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_ADDRESS_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_ADDRESS_CARD_FOOTER
          }
        />
      }
    >
      <WhmBox
        component="form"
        sx={{
          marginTop: "32px",
          marginBottom: "24px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmBox
          sx={{
            display: "flex",
            width: "100%",
            gap: "16px",
            flexWrap: { xs: "wrap", xl: "nowrap" },
          }}
        >
          <WhmTextField
            id="streetAddress"
            inputProps={{
              "data-test-id":
                "profile-personal-information-address-address-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_ADDRESS
                }
              />
            }
            defaultValue={personalInfoQuery.data?.details.address?.street}
            sx={textFieldStyle}
            disabled
          />
          <WhmTextField
            id="additionalInfo"
            inputProps={{
              "data-test-id":
                "profile-personal-information-address-additional-info-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_ADDITIONAL_INFO
                }
              />
            }
            defaultValue={personalInfoQuery.data?.details.address?.houseNumber}
            sx={textFieldStyle}
            disabled
          />
          <WhmAutocomplete
            options={[]}
            value={personalInfoQuery.data?.details.address?.city}
            fullWidth
            disabled
            renderInput={(props) => (
              <WhmTextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  "data-test-id":
                    "profile-personal-information-address-city-field",
                }}
                label={
                  <Translate
                    translationKey={
                      TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_CITY
                    }
                  />
                }
                name="city"
              />
            )}
          />
        </WhmBox>

        <WhmBox
          sx={{
            display: "flex",
            width: "100%",
            gap: "16px",
            flexWrap: { xs: "wrap", xl: "nowrap" },
            marginTop: "16px",
          }}
        >
          <WhmTextField
            id="zipCode"
            inputProps={{
              "data-test-id": "profile-personal-information-address-zip-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_POSTAL_CODE
                }
              />
            }
            defaultValue={personalInfoQuery.data?.details.address?.zip}
            sx={textFieldStyle}
            disabled
          />

          <CountryTextField
            countryCode={personalInfoQuery.data?.details.address?.countryCode}
            language={language}
            dataTestId="profile-personal-information-address-country-field"
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_COUNTRY
                }
              />
            }
          />

          {personalInfoQuery.data?.details.address?.countryCode === "US" ? (
            <WhmAutocomplete
              options={statesUS}
              value={statesUS.find(
                (state) =>
                  state.value ===
                  personalInfoQuery.data?.details.address?.state,
              )}
              fullWidth
              disabled
              renderInput={(props) => (
                <WhmTextField
                  {...props}
                  inputProps={{
                    ...props.inputProps,
                    "data-test-id":
                      "profile-personal-information-address-state-field",
                  }}
                  label={
                    <Translate
                      translationKey={
                        TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_STATE
                      }
                    />
                  }
                  name="state"
                />
              )}
            />
          ) : (
            <WhmBox sx={placeholderTextFieldStyle} />
          )}
        </WhmBox>
      </WhmBox>
    </InfoCard>
  );
};
