import { AccreditationStatus, VerificationStatus } from "../types/investors";

export const SuitabilityStatuses = {
  NONE: "none",
  PROCESSING: "processing",
  VERIFIED: "verified",
  NOT_APPROVED: "not-approved",
} as const;
export type SuitabilityStatus =
  (typeof SuitabilityStatuses)[keyof typeof SuitabilityStatuses];

export declare class InvestmentProfileDto {
  verificationStatus: VerificationStatus;
  suitabilityStatus: SuitabilityStatus;
  accreditationStatus: AccreditationStatus;
}
