import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import {
  WhmBox,
  WhmIcons,
  WhmTextField,
  WhmTooltip,
} from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import { InfoCard, textFieldStyle } from "../shared/InfoCard";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { InvestorDto } from "../../../data/dtos/investor.dto";
import { CountryTextField } from "./CountryTextField";

interface TaxJurisdictionCardProps {
  personalInfoQuery: UseQueryResult<InvestorDto, Error>;
  language: string;
}

export const TaxJurisdictionCard: React.FC<
  React.PropsWithChildren<TaxJurisdictionCardProps>
> = ({ personalInfoQuery, language }) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_FOOTER
          }
        />
      }
    >
      <WhmBox
        component="form"
        sx={{
          marginTop: "32px",
          marginBottom: "24px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmBox
          sx={{
            display: "flex",
            width: "100%",
            gap: "16px",
            flexWrap: { xs: "wrap", xl: "nowrap" },
          }}
        >
          <CountryTextField
            countryCode={personalInfoQuery.data?.details.tax[0]?.taxCountryCode}
            language={language}
            dataTestId={
              "profile-personal-information-tax-jurisdiction-country-field"
            }
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_TAX_RESIDENCE
                }
              />
            }
          />

          <WhmBox
            sx={{
              ...textFieldStyle,
              display: "flex",
              alignItems: "center",
            }}
          >
            <WhmTextField
              id="taxId"
              inputProps={{
                "data-test-id":
                  "profile-personal-information-tax-jurisdiction-tax-id-field",
              }}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_TAX_ID
                  }
                />
              }
              defaultValue={personalInfoQuery.data?.details.tax[0]?.taxId}
              disabled
              sx={{ flexGrow: 1, marginRight: "8px" }}
            />
            <WhmTooltip
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    ul: {
                      paddingLeft: "16px",
                      listStyleType: "unset",
                    },
                  },
                },
              }}
              data-test-id="profile-personal-information-tax-jurisdiction-tooltip"
              title={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_TOOLTIP
                  }
                />
              }
            >
              <WhmIcons.InfoOutlined
                sx={{ width: "16px", height: "16px", color: "GrayText" }}
              />
            </WhmTooltip>
          </WhmBox>
        </WhmBox>
      </WhmBox>
    </InfoCard>
  );
};
