import { WhmCard, WhmTypography, whmTheme } from "@securitize/reactjs-whm";
import React, { ReactNode } from "react";

// TextField Styles:
export const textFieldStyle = {
  flexGrow: { xl: 1 },
  minWidth: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "100%",
    xl: 209,
  },
  flexBasis: "100%",
};

export const placeholderTextFieldStyle = {
  ...textFieldStyle,
  display: { xs: "none", sm: "none", md: "none", lg: "none", xl: "block" },
};

interface InfoCardProps {
  title: ReactNode;
  subtitle: ReactNode;
  footer?: ReactNode;
  smallPadding?: boolean;
  children: React.ReactNode;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  footer,
  smallPadding,
  children,
}) => {
  const padding = smallPadding ? "16px" : { xs: "16px", xl: "24px" };
  return (
    <WhmCard
      variant={"outlined"}
      sx={{ padding: padding, marginTop: "20px", width: "100%" }}
    >
      <WhmTypography variant="subtitle1">{title}</WhmTypography>
      <WhmTypography
        variant="body2"
        color={whmTheme.palette.text.secondary}
        sx={{ marginBottom: "16px" }}
      >
        {subtitle}
      </WhmTypography>
      {children}
      {footer && (
        <WhmTypography
          variant="body2"
          sx={{
            a: {
              textDecoration: "underline !important",
              color: whmTheme.palette.primary.main,
            },
          }}
        >
          {footer}
        </WhmTypography>
      )}
    </WhmCard>
  );
};
