export const Genders = {
  MALE: "male",
  FEMALE: "female",
} as const;
export type Gender = (typeof Genders)[keyof typeof Genders];

export const InvestorTypes = {
  ENTITY: "entity",
  INDIVIDUAL: "individual",
} as const;
export type InvestorType = (typeof InvestorTypes)[keyof typeof InvestorTypes];

export const VerificationLevels = {
  NONE: "none",
  STANDARD: "standard",
  ENHANCED: "enhanced",
} as const;
export type VerificationLevel =
  (typeof VerificationLevels)[keyof typeof VerificationLevels];

export const VerificationStatuses = {
  NONE: "none",
  PROCESSING: "processing",
  UPDATES_REQUIRED: "updates-required",
  VERIFIED: "verified",
  MANUAL_REVIEW: "manual-review",
  REJECTED: "rejected",
  EXPIRED: "expired",
} as const;
export type VerificationStatus =
  (typeof VerificationStatuses)[keyof typeof VerificationStatuses];

export const VerificationSubStatuses = {
  EXPIRED: "expired",
  DOCUMENTS_EXPIRED: "documents-expired",
  ENHANCE_REQUESTED: "enhance-requested",
  BLOCKED: "blocked",
  NONE: "none",
} as const;
export type VerificationSubStatus =
  (typeof VerificationSubStatuses)[keyof typeof VerificationSubStatuses];

export const AccreditationStatuses = {
  NONE: "none",
  PROCESSING: "processing",
  VERIFIED: "verified",
  REJECTED: "rejected",
  NOT_ACCREDITED: "not-accredited",
  PENDING: "pending",
  EXPIRED: "expired",
  UPDATES_REQUIRED: "updates-required",
} as const;
export type AccreditationStatus =
  (typeof AccreditationStatuses)[keyof typeof AccreditationStatuses];

export const UpdateRequiredTypes = {
  ADDITIONAL_LEGAL_SIGNERS: "additional-legal-signers",
  ADDITIONAL_DOCUMENTATION: "additional-documentation",
  LEGAL_SIGNERS_MISSING_INFO: "legal-signers-missing-info",
  COMMENT_TO_INVESTOR_COMMENT: "comment-to-investor-comment",
} as const;
export type UpdateRequiredType =
  (typeof UpdateRequiredTypes)[keyof typeof UpdateRequiredTypes];

export const BrokerDealerVerificationStatuses = {
  NONE: "none",
  PROCESSING: "processing",
  VERIFIED: "verified",
  NOT_APPROVED: "not-approved",
} as const;
export type BrokerDealerVerificationStatus =
  (typeof BrokerDealerVerificationStatuses)[keyof typeof BrokerDealerVerificationStatuses];

export const AccountStatuses = {
  NONE: "none",
  APPROVED: "approved",
  REJECTED: "rejected",
} as const;
export type AccountStatus =
  (typeof AccountStatuses)[keyof typeof AccountStatuses];

export const CashAccountStatuses = {
  NONE: "none",
  OPENED_WITH_SID: "opened-with-sid",
  OPENED_WHIT_BD: "opened-with-bd",
} as const;
export type CashAccountStatus =
  (typeof CashAccountStatuses)[keyof typeof CashAccountStatuses];

export const RiskLevels = {
  NONE: "none",
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
} as const;
export type RiskLevel = (typeof RiskLevels)[keyof typeof RiskLevels];

export const AuthorizedAccountRoles = {
  ADMIN: "admin",
  VIEWER: "viewer",
  RESTRICTED_SIGNER: "restricted-signer",
};
export type AuthorizedAccountRole =
  (typeof AuthorizedAccountRoles)[keyof typeof AuthorizedAccountRoles];
