import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Catalog from "./views/Catalog";
import Registration from "./components/Registration";
import UserMenu from "./views/UserMenu";
import MFEContainer from "./containers/MFEContainer";
import KeyParties from "./views/KeyParties";
import PhoneVerification from "./views/PhoneVerification";
import Profile from "./views/Profile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Catalog />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
  {
    path: "/key-parties",
    element: <KeyParties />,
  },
  {
    path: "/phone-verification",
    element: <PhoneVerification />,
  },
  {
    path: "/user-menu",
    element: <UserMenu />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
]);

function App() {
  return (
    <MFEContainer language="EN">
      <RouterProvider router={router} />
    </MFEContainer>
  );
}

export default App;
