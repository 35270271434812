export enum KycStatus {
  VERIFIED = "verified",
  PROCESSING = "processing",
  PENDING = "pending",
  MANUAL_REVIEW = "manual-review",
  UPDATES_REQUIRED = "updates-required",
  REJECTED = "rejected",
  EXPIRED = "expired",
  NOT_VERIFIED = "not-verified",
}
