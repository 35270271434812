import {
  WhmCircularProgress,
  WhmScopedThemeProvider,
} from "@securitize/reactjs-whm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18nContextProvider } from "../../contexts/I18nContext";

export interface MFEContainerProps {
  language: string;
  theme?: "LIGHT" | "DARK";
}

export type MFEProps<T> = MFEContainerProps & T;

export default function MFEContainer({
  children,
  language,
  theme,
}: MFEContainerProps & { children: React.ReactNode }) {
  const queryProvider = new QueryClient({
    defaultOptions: {
      queries: {
        // We don't want to refetch every time the window is focused
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryProvider}>
      <I18nContextProvider
        language={language}
        loadingComponent={<WhmCircularProgress />}
      >
        <WhmScopedThemeProvider dark={theme === "DARK"}>
          {children}
        </WhmScopedThemeProvider>
      </I18nContextProvider>
    </QueryClientProvider>
  );
}
