import React from "react";
import {
  WhmBox,
  WhmSkeleton,
  WhmStack,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { Translate } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { useResolvedPath } from "react-router";
import { useGetInvestmentProfileQuery } from "../../../data/useProfile";
import { InvestmentLoadingCard } from "./InvestmentLoadingCard";
import { SuitabilityCard } from "./suitability/SuitabilityCard";
import { AccreditationCard } from "./accreditation/AccreditationCard";

export interface InvestmentProfileProps {
  isLoading: boolean;
}

export const InvestmentProfile = ({ isLoading }: InvestmentProfileProps) => {
  const { pathname: currentPath } = useResolvedPath("");

  const investmentProfileQuery = useGetInvestmentProfileQuery();

  // Main UI
  if (investmentProfileQuery.isPending || isLoading)
    return (
      <>
        <WhmSkeleton
          data-test-id="profile-investment-profile-top-skeleton"
          sx={{ marginTop: "16px" }}
          variant="rounded"
          width={"100%"}
          height={52}
        ></WhmSkeleton>
        <WhmBox
          sx={{
            padding: "20px 0px",
            flexDirection: { xs: "column", xl: "row" },
            display: "flex",
            gap: "20px",
          }}
        >
          <InvestmentLoadingCard />
          <InvestmentLoadingCard />
        </WhmBox>
      </>
    );

  if (investmentProfileQuery.data) {
    return (
      <>
        <WhmStack sx={{ padding: "16px 0px" }}>
          <WhmTypography variant="h4">
            <Translate
              translationKey={TranslationKeys.PROFILE_INVESTMENT_PROFILE_TITLE}
            />
          </WhmTypography>
          <WhmTypography variant="body2" color="GrayText">
            <Translate
              translationKey={
                TranslationKeys.PROFILE_INVESTMENT_PROFILE_SUBTITLE
              }
            />
          </WhmTypography>

          <WhmBox
            sx={{
              flexDirection: { xs: "column", xl: "row" },
              display: "flex",
              alignItems: "baseline",
              gap: { xs: "0", xl: "20px" },
            }}
          >
            <SuitabilityCard
              investmentProfileData={investmentProfileQuery.data}
              currentPath={currentPath}
            />

            <AccreditationCard
              investmentProfileData={investmentProfileQuery.data}
              currentPath={currentPath}
            />
          </WhmBox>
        </WhmStack>
      </>
    );
  } else {
    // TODO - Replace no data scenario with error screen in a future task
    return <WhmBox>No data</WhmBox>;
  }
};
