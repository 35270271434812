import { WhmBox, WhmContainer } from "@securitize/reactjs-whm";
import { Profile } from "../../components/Profile/Profile";
import UserContext from "../../contexts/UserContext";
import { KycStatus } from "../../types/KycStatus";

export default function ProfileView() {
  return (
    <WhmContainer>
      <WhmBox>
        <UserContext
          userInfo={{
            investorId: "65a009a8b8b35d0efeeaab3e",
            email: "esteban.butti+dev@securitize.io",
            contactEmail: "esteban.butti+dev@securitize.io",
            fullName: "Estevan Butti",
            verificationStatus: KycStatus.VERIFIED,
            entityName: "",
            suitabilityStatus: "approved",
            accountStatus: "approved",
            investorType: "individual",
            state: "AZ",
            countryCode: "US",
            brokerDealerId: 24,
            accreditationStatus: "processing",
            language: "EN",
            contactDetails: {
              phone: {
                code: "1",
                number: "201 333 4444",
                fullNumber: "+12013334444",
                isVerified: true,
              },
            },
            investorUserId: "65a009a8b8b35d0efeeaab40",
            investorUserEmail: "esteban.butti+dev@securitize.io",
            userInvestors: [
              {
                investorId: "65a009a8b8b35d0efeeaab3e",
                createDate: "2024-01-11T15:30:48.612Z",
                verificationErrors: [],
                allVerificationErrors: [],
                isHidden: false,
                details: {
                  investorType: "individual",
                  address: {
                    countryCode: "US",
                    state: "AZ",
                    city: "Miami",
                    entrance: "",
                    street: "    6911 NW 82 Av",
                    zip: "33166",
                    houseNumber: "",
                  },
                  comments: [],
                  verificationComments: [],
                  tax: [
                    {
                      taxId: "476-47-3374",
                      taxCountryCode: "US",
                      _id: "672119c1dd185e6c6b3c3f82",
                    },
                  ],
                  displayName: "Estevan  Butti",
                  phone: {
                    isVerified: true,
                    fullNumber: "+12013334444",
                    code: "1",
                    number: "201 333 4444",
                  },
                  birthCity: "Miami",
                  birthCountry: "US",
                  birthState: "FL",
                  gender: "male",
                  birthday: "1989-12-27T00:00:00.000Z",
                  comment: "",
                  firstName: "Estevan",
                  lastName: "Butti",
                },
                email: "esteban.butti+dev@securitize.io",
                contactEmail: "esteban.butti+dev@securitize.io",
                mixPanelId: "",
                analyticsEnabled: false,
                verificationLevel: "standard",
                verificationStatus: "verified",
                verificationSubStatus: "enhance-requested",
                accreditationStatus: "processing",
                accreditationStatusComment: null,
                accreditationStatusComments: [],
                accreditationStatusChangeDate: "2024-11-13T15:32:33.579Z",
                accreditationId: "f29256f0-04ab-4584-bf4a-83ca4e61077c",
                pendingDeletion: false,
                deletionDeclineDate: null,
                lastIssuerContext: {
                  issuerId: null,
                  date: "2024-01-11T15:30:48.612Z",
                },
                brokerDealerId: 24,
                requestedUpdates: [],
                labels: [],
                accountStatus: "approved",
                accountStatusSignature: "",
                accountEuStatus: "approved",
                accountEuStatusSignature: "",
                assignedAttorneyOperatorId: "none",
                annualIncome: [],
                approvedIssuerIds: ["a4143904-0cbd-4c89-9526-9605d4d574a2"],
                lastLoggedIn: "2024-12-13T18:33:40.089Z",
                role: "admin",
              },
            ],
            isEnhancedVerificationRequested: true,
            isOwner: true,
          }}
        >
          <Profile />
        </UserContext>
      </WhmBox>
    </WhmContainer>
  );
}
