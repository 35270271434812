import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import React, { useMemo } from "react";
import { InvestmentProfileTabCard } from "../InvestmentProfileTabCard";
import { InvestmentProfileDto } from "../../../../data/dtos/investment-profile.dto";
import { VerificationStatuses } from "../../../../data/types/investors";
import { AccreditationAlert } from "./AccreditationAlert";
import { AccreditationButton } from "./AccreditationButton";

interface AccreditationCardProps {
  investmentProfileData?: InvestmentProfileDto;
  currentPath: string;
}

export const AccreditationCard: React.FC<AccreditationCardProps> = ({
  investmentProfileData,
  currentPath,
}) => {
  const accreditationAlert: React.ReactNode = useMemo(() => {
    return (
      investmentProfileData &&
      investmentProfileData.verificationStatus ===
        VerificationStatuses.NONE && (
        <AccreditationAlert currentPath={currentPath} />
      )
    );
  }, [investmentProfileData, currentPath]);

  const accreditationButton: React.ReactNode = useMemo(() => {
    return (
      investmentProfileData && (
        <AccreditationButton
          accreditationStatus={investmentProfileData.accreditationStatus}
          verificationStatus={investmentProfileData.verificationStatus}
          currentPath={currentPath}
        />
      )
    );
  }, [investmentProfileData, currentPath]);

  return (
    <InvestmentProfileTabCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_TITLE
          }
        />
      }
      subtitle={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_SUBTITLE
          }
        />
      }
      alert={accreditationAlert}
      button={accreditationButton}
    />
  );
};
