import React, { ReactNode, useMemo } from "react";
import { WhmAutocomplete, WhmBox, WhmTextField } from "@securitize/reactjs-whm";
import { getCountriesList } from "../../Registration/countriesList";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

interface CountryTextFieldProps {
  countryCode: string | undefined;
  language: string;
  label: ReactNode;
  dataTestId?: string;
}

export const CountryTextField: React.FC<CountryTextFieldProps> = ({
  countryCode,
  language,
  label,
  dataTestId,
}) => {
  const countryList = useMemo(() => getCountriesList(language), [language]);
  const countryValue = useMemo(
    () => countryList.find((country) => country.value === countryCode),
    [countryCode, countryList],
  );

  return (
    <WhmAutocomplete
      fullWidth
      disabled
      isOptionEqualToValue={(_, __) => true}
      value={countryValue}
      options={countryList}
      renderInput={(params) => (
        <WhmTextField
          {...params}
          name="country"
          inputProps={{
            ...params.inputProps,
            "data-test-id": dataTestId,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <WhmBox sx={{ fontSize: "24px" }}>
                {countryCode ? getUnicodeFlagIcon(countryCode) : null}
              </WhmBox>
            ),
          }}
          label={label}
        />
      )}
    />
  );
};
