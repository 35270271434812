export const BASE_PATH = "/new-profile";

export const tabRoutes = {
  personalInformation: "personal-information",
  taxCenter: "tax-center",
  investmentProfile: "investment-profile",
  settings: "settings",
};
export const profilePaths = {
  home: `${BASE_PATH}/${tabRoutes.personalInformation}`,
  personalInformation: `${BASE_PATH}/${tabRoutes.personalInformation}`,
  taxCenter: `${BASE_PATH}/${tabRoutes.taxCenter}`,
  investmentProfile: `${BASE_PATH}/${tabRoutes.investmentProfile}`,
  settings: `${BASE_PATH}/${tabRoutes.settings}`,
};

export const tabRoutesToProfilePaths: Record<string, string> = {
  [tabRoutes.personalInformation]: profilePaths.personalInformation,
  [tabRoutes.taxCenter]: profilePaths.taxCenter,
  [tabRoutes.investmentProfile]: profilePaths.investmentProfile,
  [tabRoutes.settings]: profilePaths.settings,
};

export const INVESTMENT_PROFILE_URL = (redirectUrl: string): string => {
  const search = new URLSearchParams({ redirectUrl });
  return `/profile/suitability?${search.toString()}`;
};
export const VERIFICATION_URL = (redirectUrl: string): string => {
  const search = new URLSearchParams({ redirectUrl });
  return `/profile/verification/identity-verification?${search.toString()}`;
};
export const ACCREDITATION_URL = (redirectUrl: string): string => {
  const search = new URLSearchParams({ redirectUrl });
  return `/profile/accreditation?${search.toString()}`;
};
