import {
  WhmCard,
  WhmContainer,
  WhmList,
  WhmListItem,
  WhmListItemButton,
} from "@securitize/reactjs-whm";

export default function Catalog() {
  return (
    <WhmContainer>
      <WhmCard>
        <WhmList>
          <WhmListItem disablePadding>
            <WhmListItemButton href="/registration">
              Registration
            </WhmListItemButton>
          </WhmListItem>
          <WhmListItem disablePadding>
            <WhmListItemButton href="/key-parties">
              Key Parties
            </WhmListItemButton>
          </WhmListItem>
          <WhmListItem disablePadding>
            <WhmListItemButton href="/phone-verification">
              Phone Verification
            </WhmListItemButton>
          </WhmListItem>
          <WhmListItem disablePadding>
            <WhmListItemButton href="/user-menu">User Menu</WhmListItemButton>
          </WhmListItem>
          <WhmListItem disablePadding>
            <WhmListItemButton href="/profile">Profile</WhmListItemButton>
          </WhmListItem>
        </WhmList>
      </WhmCard>
    </WhmContainer>
  );
}
